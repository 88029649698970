@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
    color: #fff;
    font-family: "Open Sans", sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  max-width: 100%;
}

.app {
  background-color: #343541;
  display: flex;
  height: 100%;
}

.side-bar {
  background-color: #202123;
  height: 100vh;
  width: 244px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button {
  border: solid 0.5px rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

nav {
  border-top: solid 0.5px rgba(255, 255, 255, 0.5); 
  padding: 10px;
  margin: 10px;
}

.history {
  padding: 10px;
  margin: 10px;
  height: 100%;
}

.history li {
  list-style: none;
  padding: 15px 0;
  cursor: pointer;

}

.feed li {
  list-style: none;
  padding: 15px 0;
}

.main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.info {
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
  padding: 10px;
}

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container {
  position: relative;
  width: 75%;
  max-width: 650px;
}

@media (min-width: 1024px) {
  .input-container {
    width: 100%; /* Quarter width on large screens and up */
  }
}

input {
  width: 100%;
  border: none;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 54px 55px,  
              rgba(0, 0, 0, 0.05) 0px -12px 30px,
              rgba(0, 0, 0, 0.05) 0px 4px 6px,
              rgba(0, 0, 0, 0.05) 0px 12px 3px,
              rgba(0, 0, 0, 0.05) 0px -3px 5px;
}

input:focus {
  outline: none;
}

#submit {
  position: absolute;
  right: 0;
  bottom: 15px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
}

.feed {
  overflow-y: scroll; /* Set vertical scroll */
  overflow-x: hidden; /* Disable horizontal scroll */
  width: 100%;
  padding: 0;
}

.feed li {
  display: flex;
  background-color: #444654;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
}

.feed p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  text-align: left;
}

.feed p.role {
  min-width: 100px;
}

/* CSS */
.hamburger-button {
  display: block; /* Show the button on small screens */
}

@media (min-width: 768px) {
  .hamburger-button {
    display: none; /* Hide the button on medium screens and up */
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.hamburger-container {
  flex: 1;
}

.header {
  flex: 1;
  display: flex;
  justify-content: center;
}

.hamburger-button {
  border: none;
  background: none;
  font-size: 24px; /* Adjust as needed */
  left: 0;
  position: absolute;
}

@media (max-width: 768px) {
  .side-bar {
    height: calc(100vh - 10vh); /* Subtract the padding from the height */
    padding-bottom: 10vh;
  }

  .main {
    height: calc(100vh - 10vh); /* Subtract the padding from the height */
    padding-bottom: 10vh;
  }
}